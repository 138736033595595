.tab-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab-scroll::-webkit-scrollbar {
  display: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.context-menu-item {
  padding: 2px 5px 2px 5px;
}

.context-menu-item:hover {
  background-color: lightgray;
}

.analysis-modal-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.25);
}

.analysis-modal-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.analysis-modal-main {
  background-color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  border: 1px solid black;
  /* padding: 5px; */
  z-index: 300;
}

.processing-select {
  padding: 5px;
  border-bottom: 1px solid black;
}

.processing-select:last-child {
  border-bottom: 0;
}

.processing-select:hover {
  background-color: lightgray;
}

/* .tab {
  display: flex;
  align-items: center;
  width: 200px;
  height: 32px;
  padding: 5px;
} */

/* .tab:last-child {
  margin-right: -1px;
  float: right;
} */