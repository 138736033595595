#root {
    height: 100%;
}

.page {
    display: flex;
    height: 100%;
    min-height: 600px;
    background-color: #EBEEF3;
}

.sidebar {
    display: flex;
    flex-direction: column;
    width: 280px;
    min-width: 280px;
    height: 100%;
    background-color: black;
    z-index: 100;
    top: 0;
    left: 0;
}

.sidebar-top {
    /* display: flex; */
    padding: 10px;
    /* width: 100%; */
}

.sidebar-line {
    margin: 0;
    background-color: white;
}

/* .menu-list {
    width: 100%;
} */

.test {
    display: none;
}

.menu-item {
    height: 40px;
    width: 100%;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.dtx-content {
    /* flex-grow: 1; */
    margin-top: 35px;
    padding: 10px;
    overflow-x: auto;
    width: 100%
}

.modal-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal-main {
    background-color: white;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 300;
    padding: 15px;
}

ul {
    list-style-type: none;
    padding: 0;
    /* margin: 0; */
}

.exerciseList {
    height: 100%;
    border: 1px solid;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px !important;
}

.listItem {
    background-color: white;
    border: 1px solid;
    /* border-radius: 5px; */
    padding: 10px;
    margin: -1px;
}

.listItem:hover {
    background-color: #EEEEEE;
}

.item-selected {
    background-color: #007bff;
    color: white;
}

.item-unselected {
    cursor: pointer;
    background-color: white;
    color: black;
}

.item-unselected:hover {
    background-color: #DDDDDD;
}

/* .listItem:active {
    background-color: #DDDDDD;
}

.listItem:active:has(input:active) {
    background-color: #EEEEEE;
} */

.prescriptionSub {
    height: 100%;
    border: 1px solid;
    border-radius: 5px;
    /* overflow-y: auto; */
    overflow-x: hidden;
    margin-bottom: 5px !important;
}

.prescription {
    height: 100%;
    border: 1px solid;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0 !important;
}

.tableCell {
    display: table-cell;
    padding: 0 0 2px 2px;
}

/* .exercise:hover {
    cursor: grab;
    background-color: #ccc;
}

.exercise:active {
    cursor: grabbing;
    background-color: #ccc;
} */

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
}

input[type="range"]:disabled {
    cursor: default;
}
 
 /* Removes default focus */
 /* input[type="range"]:focus {
   outline: none;
 } */
 
 /***** Chrome, Safari, Opera and Edge Chromium styles *****/
 /* slider track */
input[type="range"]::-webkit-slider-runnable-track {
    background-color: #aaaaff;
    border-radius: 0.2rem;
    height: 0.4rem;  
}
 
 /* slider thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.4rem; /* Centers thumb on the track */
    border-radius: 50%;
    /*custom styles*/
    background-color: #4444ff;
    height: 1.2rem;
    width: 1.2rem;
}

input[type="range"]:disabled::-webkit-slider-runnable-track {
    background-color: #aaaaaa;
}
 
input[type="range"]:disabled::-webkit-slider-thumb {
    background-color: #4444aa;
}
 
 /* input[type="range"]:focus::-webkit-slider-thumb {   
   border: 1px solid #053a5f;
   outline: 3px solid #053a5f;
   outline-offset: 0.125rem; 
 } */
 
 /******** Firefox styles ********/
 /* slider track */
input[type="range"]::-moz-range-track {
    background-color: #aaaaff;
    border-radius: 0.2rem;
    height: 0.4rem;  
}
 
 /* slider thumb */
input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0.4rem; /*Removes default border-radius that FF applies*/
 
    /*custom styles*/
    background-color: #4444ff;
    height: 1.2rem;
    width: 1.2rem;
}

input[type="range"]:disabled::-moz-range-track {
    background-color: #aaaaaa;
}
 
input[type="range"]:disabled::-moz-range-thumb {
    background-color: #4444aa;
}

.dtxCalendar {
    border: none !important;
    height: 300px !important;
}

.dtx_tile {
    padding: 2px 0 !important;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 1.5em 0 !important;
}

.react-calendar__tile--active.dtx_tile {
    background-color: white;
    color: black;
    box-shadow: 0 0 0 3px #007bff inset;
}

.react-calendar__tile--active.dtx_tile:enabled:hover, .react-calendar__tile--active.dtx_tile:enabled:focus {
    background-color: white;
}

.react-calendar__tile--now.dtx_tile:enabled:hover {
    background-color: #e6e6e6;
}

.react-calendar__tile--now.dtx_tile {
    background-color: white;
}

.react-calendar__tile--active:enabled.dtx_tile.abnormal_day, .dtx_tile.abnormal_day {
    background-color: #FF000022;
}
