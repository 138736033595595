.exoRehab {
    width: 400px;
    vertical-align: middle;
    transition: all 1s;
    position: absolute;
    /* right: 50%; */
    /* margin-right: 8%; */
    transform: translate(-200px);
}

.exoFit {
    width: 295px;
    vertical-align: middle;
    transition: all 1s;
    position: absolute;
    /* left: 50%; */
    /* margin-left: 12%; */
    text-align: center;
    transform: translate(-148px);
}

.dtx {
    width: 272px;
    vertical-align: middle;
    transition: all 1s;
    position: absolute;
    /* padding-top: 50px;
    padding-right: 50px; */
    /* left: 50%; */
    /* margin-left: 12%; */
    text-align: center;
    transform: translate(-136px);
}

.exoRehab-expand {
    cursor: default;
    /* margin-right: -200px; */
}

.exoFit-expand {
    cursor: default;
    /* margin-left: -149px; */
}

.dtx-expand {
    cursor: default;
    /* margin-left: -149px; */
}

/* .exoRehab-fold {
    margin-right: 250px;
}

.exoFit-fold {
    margin-left: 299px;
} */

.img {
    cursor: default;
    padding: 30px;
    border: 1px solid #00000000;
    border-radius: 10px;
    transition: all 1s;
}

.border {
    cursor: pointer;
    border: 1px solid #777777;
}

.form {
    margin: -30px 30px 30px;
    transition: all 1s;
}

.form-hidden {
    visibility: hidden;
    opacity: 0;
}

.login {
    width: 238px;
    margin-left: -1px;
    margin-top: 10px;
}

.login:hover {
    cursor: pointer;
}

@keyframes leftToCenter {
    from { left: 16.7% }
    to { left: 50% }
}

@keyframes centerToRight {
    from { left: 50% }
    to { left: 83.3% }
}

@keyframes centerToLeft {
    from { left: 50% }
    to { left: 16.7% }
}

@keyframes rightToCenter {
    from { left: 83.3% }
    to { left: 50% }
}

@keyframes outsideToLeft {
    from { left: -16.7% }
    to { left: 16.7% }
}

@keyframes outsideToRight {
    from { left: 116.7% }
    to { left: 83.3% }
}

@keyframes leftToOutside {
    from { left: 16.7% }
    to { left: -16.7% }
}

@keyframes rightToOutside {
    from { left: 83.3% }
    to { left: 116.7% }
}