#root {
    height: 100%;
}

.page {
    display: flex;
    height: 100%;
}

.sidebar {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
    background-color: black;
    z-index: 10000;
    top: 0;
    left: 0;
}

.sidebar-fold {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: 100%;
    background-color: black;
    z-index: 10000;
    top: 0;
    left: 0;
    cursor: pointer;
}

.sidebar-fold:hover {
    background-color: #333333;
}

.sidebar-top {
    /* display: flex; */
    padding: 5px;
    /* width: 100%; */
}

.sidebar-line {
    margin: 0;
    background-color: white;
}

/* .menu-list {
    width: 100%;
} */

.test {
    display: none;
}

.menu-item {
    height: 40px;
    width: 100%;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.menu-item:hover {
    background-color: #333333;
}

.menu-item:focus {
    outline: none;
}

.v2-content {
    flex-grow: 1;
    padding: 10px;
}

.tile {
    padding: 2px 0 !important;
}

/* div.plotly-notifier {
    visibility: hidden;
} */