svg.graph {
  border: 0.5px solid lightgray;
}
svg.graph polyline {
  opacity: 0.3;
  stroke: black;
  stroke-width: 2px;
  fill: none;
}
.bar {
  fill: skyblue;
}
.bar:hover {
  fill: blue;
}
.barfill {
  fill: transparent;
}
.text {
  fill: white;
  font-weight: bold;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
}
.modal-full {
  min-width: 50%;
  z-index: 9999999;
}

.calendar,
.chipgroup {
  text-align: center;
}

.react-calendar,
.MuiChip-root {
  display: inline-block;
}

.userlist {
  height: 500px;
  overflow-y: scroll;
}

.rehablist {
  height: 330px;
  overflow-y: scroll;
}

.exerciselist {
  height: 420px;
  overflow-y: auto;
}

.mqlist {
  height: 420px;
  overflow-y: auto;
}

.feslist {
  height: 420px;
  overflow-y: auto;
}

.dots {
  display: none;
}

.mbsc-ios.mbsc-segmented.mbsc-segmented-group {
  padding: 0;
  margin: 0;
  border-radius: 0; }

.bg-indianred {
  background-color: indianred !important;
}

.bg-orange {
  background-color: orange !important;
}

.bg-yellow {
  background-color: yellow !important;
}

.bg-lightgreen {
  background-color: lightgreen !important;
}

.bg-skyblue {
  background-color: skyblue !important;
}

.bg-royalblue {
  background-color: royalblue !important;
}

.bg-blueviolet {
  background-color: blueviolet !important;
}

.bg-violet {
  background-color: violet !important;
}

.bg-lightpink {
  background-color: lightpink !important;
}

.bar.positive {
  fill: indianred;
}

.bar.negative {
  fill: steelblue;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

/* .functionButton {
  background-color: #e0e0e0;
} */

.functionButton:hover {
  background-color: #cccccc;
  cursor: pointer;
}

th, td {
  border: 1px solid
}
